<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- ********************* OVERVIEW *************************** -->
        <section id="overview">
          <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div>

          <ReadMore>
            <div>
              <i18n
                path="overview.paragraph2.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link1>
                  <a @click="gotoStep(3)">{{
                    $t("overview.paragraph2.link1")
                  }}</a>
                </template>
              </i18n>
            </div>
          </ReadMore>
        </section>

        <!-- ***************************** SUMMARY ***************************** -->
        <!-- <div class="steps__subtitle bsat__subtitle mb-4">
          {{ $t("summary.title") }}
        </div>
        <div
          v-for="(item, i) in summaryUnorderedList"
          :key="i"
          class="bsat__paragraph"
          v-text="item"
        ></div> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.taskLeader.title")
              }}</strong>
            </div>
            <ul>
              <li
                class="bsat__paragraph mb-4"
                v-for="(item, i) in taskLeaderList"
                :key="i"
              >
                {{ item }}
              </li>
            </ul>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>

        <!-- ***************************** RECOMMENDED RESOURCES ***************************** -->
        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recommendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph") }}
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recommendedResources.paragraph2.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <a @click="gotoTab(1)">{{
                      $t("recommendedResources.paragraph2.link")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recommendedResources.paragraph3.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <a @click="gotoStep(2)">{{
                      $t("recommendedResources.paragraph3.link")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph4") }}
              </div>
            </li>

            <li>
              <!-- ***************************** REGIONAL POLICIES ***************************** -->
              <div>
                <i18n
                  path="recommendedResources.regionalPolicies.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{
                      $t("recommendedResources.regionalPolicies.title")
                    }}</strong>
                  </template>
                </i18n>

                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  :to="regionalPoliciesLink"
                  >{{ $t("visit") }}</v-btn
                >

                <!-- <a
                  :href="require('@/assets/files/financial_tools.docx').default"
                  target="_blank"
                  download
                >
                  <v-btn
                    class="bsat__btn"
                    color="dark"
                    dark
                    depressed
                    width="150"
                    height="26"
                    >{{ $t("download") }}</v-btn
                  >
                </a> -->
              </div>
            </li>

            <li>
              <!-- **************** TRAINING SECTORS *************************** -->

              <div>
                <i18n
                  path="recommendedResources.trainingSectors.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>
                      {{ $t("recommendedResources.trainingSectors.title") }}
                    </strong>
                  </template>
                </i18n>
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  to="/training-in-bioeconomy-sectors"
                  >{{ $t("visit") }}</v-btn
                >
              </div>
            </li>
          </ul>
        </section>
      </div>

      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
	"en": {
		"implementationRecommendations": {
			"proposedMethods": {
				"title": "Proposed methods",
				"unorderedList": [
					"Back casting workshop with engaged stake-holders and advisory group."
				]
			},
			"task": {
				"text": "{title}: Update (if necessary) priority areas, main goals and specific goals according to the results of Step 2.",
				"title": "Task"
			},
			"taskLeader": {
				"title": "Task Leader",
				"unorderedList": [
					"Strategy update working group. ",
					"In close cooperation with key stakeholders engaged through the participatory process"
				]
			},
			"title": "Implementation Recommendations"
		},
		"overview": {
			"paragraph": "Updating, modifying or including a (new) priority area to the strategy mean a challenge as it requires additional efforts in harmonizing the content of the overall bioeconomy strategy. To this end, it is required to structure and plan new required actions within the strategy. Also, it is important not to proceed with identifying new priority areas based on the existing ones, or at least not under the action areas they are part of. When adding or modifying a new priority area, it is always recommended to verify that this is in line with the regional bioeconomy vision and that its area of action is not overlapping  with any other priority area.",
			"paragraph2": {
				"link1": "Step 2",
				"text": "The results from the involvement of stakeholders from {link1}, will be paramount at this stage. It will feed into the decision-making process in the search of new priority areas. Also, potential adaptations of the existing priority areas might be flagged at this stage.  Any new or updated priority areas should hold a clear main goal and specific goals, which later will be reflected on the updated roadmap."
			}
		},
		"recommendedResources": {
			"paragraph": "Current regional bioeconomy strategy vision",
			"paragraph2": {
				"link": "Phase 1",
				"text": "Results of the SAT analysis and policy analysis ({link})"
			},
			"paragraph3": {
				"link": "Step 2",
				"text": "Analysis of regional bioeconomy challenges from interviews with regional experts ({link})"
			},
			"paragraph4": "Results of applying the existing KPIs in your region against the current regional bioeconomy strategy status.",
			"regionalPolicies": {
				"text": "{title} Identify areas of priority, based on supporting policies thoroughly described and good examples . Policy information from the bioeconomy field at this stage, from a helicopter view, will help the region assess the general elements to bear in mind in the following steps and phases. Specially consult: Policy overview and Brief tips for policy analysis in the field of bioeconomy",
				"title": "Repository of Supporting documents and tools for regional policies in the field of bioeconomy.: "
			},
			"title": "Recommended resources ",
			"trainingSectors": {
				"text": "{title} POWER4BIO training materials are an elaborated source of elaborated analysis and descriptions to use for training purposes. They include webinars developed in the 4 bio applications (bioenergy. Food&feed, biochemicals and biomaterials), dealing with a wide range of key aspects.",
				"title": "Training in Bioeconomy sectors: "
			}
		},
		"summary": {
			"title": "Summary of possible methods",
			"unorderedList": [
				"Back casting workshop with engaged stake-holders and advisory group. "
			]
		},
		"title": "Step 3: Update priority areas, corresponding main and specific goals",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
	}
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "UpdatePhase3Step3",
  components: {
    ReadMore
  },
  data() {
    return {
      regionalPoliciesLink:
        "/repository-of-regional-policies-to-support-bio-based-models",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    taskLeaderList() {
      return Object.values(
        this.$t("implementationRecommendations.taskLeader.unorderedList")
      );
    },
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recomendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    gotoStep(step) {
      EventBus.$emit("goToStep", step);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
